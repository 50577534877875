import React, { useRef, useEffect } from "react";
import './WebcamVideo.css'



export default function WebcamVideo({webcamStream}) {

    const webcamRef = useRef(null);
    useEffect(()=>{
        if (webcamStream && webcamRef.current) {
            webcamRef.current.srcObject = webcamStream.stream;
        }
        
    }, [webcamRef, webcamStream])

    const canPlayHandler=() => {
        if (webcamStream && webcamRef.current) {
            webcamRef.current.play()
        }
    }


    return (
            <video 
                ref={webcamRef}
                className="webcamVideo"
                autoPlay
                height="480" 
                width="480" 
                onCanPlay={canPlayHandler}
                playsInline
                muted 
            />
    );

}