
import React from 'react';
import './Recorder.css'
import VideoDisplays from '../VideoDisplays/VideoDisplays.jsx';

export default function Recorder(){
    
    return (
            <> 
                <VideoDisplays/> 
            </> 
    )
}
