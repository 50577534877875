import React, { useEffect, useRef } from "react";
import './ScreenVideo.css'

export default function ScreenVideo({screenStream}) {
    const screenRef = useRef(null);
    useEffect(()=>{
        if (screenStream && screenRef.current) {
            screenRef.current.srcObject = screenStream.stream;
        }
    }, [screenRef, screenStream])

    return (
            <video 
                className="screenVideo"
                ref={screenRef}  
                autoPlay 
                height="720" 
                width="1280" 
                playsInline
                muted 
            />
    );

}