import React, { useEffect, useRef, useState } from 'react';
import ScreenVideo from '../ScreenVideo/ScreenVideo.jsx';
import WebcamVideo from '../WebcamVideo/WebcamVideo.jsx';
import saveAsService from '../../services/saveAsService';
import { webmFixDuration } from 'webm-fix-duration';
import JSZip from 'jszip';
import './VideoDisplays.css'
import FragmentFileServiceUpload from '../../services/fragment-file.service.jsx'
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar.jsx';
import { Link, useLocation } from 'react-router-dom';
import authHeader from '../../services/auth-header';
import TestingService from '../../services/testing.service';
import TestForm from '../TestForm/TestForm.jsx'

const screenConstraints = {
        audio: { "echoCancellation": true },
        video: { "width": 1280, "height": 720 },
};
const webcamConstraints = {
    audio: { "echoCancellation": true },
    video: { "width": 640, "height": 480 },
};


export default function VideoDisplays() {

    // Show components conditions
    const [showButtons, setShowButtons] = useState(false);
    const [showDisplays, setShowDisplays] = useState(false);
    const [showTestState, setShowTestState] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    
    //Auth and relation elements
    const optionalParams = new URLSearchParams(useLocation().search);
    const {
        finishTestSession,
        showTest
    } = TestingService();
    const [testInfo, setTestInfo] = useState(null);

    // Recorder Elements 
    const [innerButton, setInnerDownloadButton] = useState('Detener grabación');
    const [innerText, setText] = useState("Comparte tu pantalla");
    const [permissions, setPermission] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const startRecordingButton = useRef(null);
    const stopRecordingButton = useRef(null);
    const webcamRecorder = useRef(null);
    const screenRecorder = useRef(null);
    const [webcamBuild, setWebcamBuild] = useState(null);
    const [screenBuild, setScreenBuild] = useState(null);
    const [webcamChunks, setWebcamChunks] = useState([]);
    const [screenChunks, setScreenChunks] = useState([]);
    const [startScreenTime, setStartTime] = useState(0);

    // Progress bar elements
    const [progress, setProgress] = useState(0)
    const [loadingLabelColor, setLoadingLabelColor] = useState('#F2EDEB');
    const [loadingLabel, setLoadingLabel] = useState('Cargando...');
    const [loadingIndicatorColor, setLoadingIndicatorColor] = useState('#FABC2A');
    const zip = new JSZip()

    // Upload Elements
    const [hadErrors, setHadErrors] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isVideoSaved, setIsVideoSaved] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isSurveyPosted, setSurveyPosted] = useState(false);
    const [savingInfoText, setSavingInfoText] = useState('');

    useEffect(()=>{
        
        if(optionalParams.has('id') && testInfo==null){
            let receivedId = optionalParams.get('id');
            setTestInfo({id:receivedId})
            authHeader().then(token =>{
                showTest(token, receivedId)
                .then(res => {
                    let userTestInfo = res.data.user_test

                    setTestInfo(t => ({
                        ...t,
                        ...res.data,
                        'user_test': userTestInfo
                    }));
                    
                    setSurveyPosted(userTestInfo.is_survey_uploaded)
                    setIsVideoSaved(userTestInfo.is_video_uploaded)
                    if(userTestInfo.finished_at != null){
                         setIsCompleted(true);
                    }
                    setHadErrors(false);
                })
                .catch((err)=>{
                    console.log(err)
                    setHadErrors(true)
                })
            });
        }

        if(isVideoSaved){
            setLoadingLabelColor('#74EB56');// Green progressbar when video is saved
            setLoadingIndicatorColor('#74EB56');// Green progressbar text when video is saved
        }

        updateState();
        
        if(startRecordingButton.current && stopRecordingButton.current && !permissions && !isRecording){
            startRecordingButton.current.disabled = true;
            stopRecordingButton.current.disabled = true;
        }
        else if(startRecordingButton.current && stopRecordingButton.current && permissions && !isRecording && !isSaving){
            startRecordingButton.current.disabled = false;
            stopRecordingButton.current.disabled = true;
        }
        
    }, [isSaving, isRecording, permissions, isVideoSaved, isSurveyPosted, isCompleted, hadErrors])
  

    const updateState = () => {
        if(isSurveyPosted && isVideoSaved){
            setIsCompleted(true);
            setHadErrors(false);
        }
        if(hadErrors){
            setLoadingLabelColor('#FF0057');
            setLoadingIndicatorColor('#FF0057');
            setLoadingLabel('Ups. :(');
            setSavingInfoText(
                'Parece que ha habido un problema al subir la información.'
            );
        }
        else {
            if(testInfo!=null){
                if(!screenBuild && !webcamBuild && !isVideoSaved && !isCompleted && !isSaving){
                    getScreenPermission();
                }
                // Survey conditions
                if(!isCompleted ){
                    if(!isSurveyPosted && !isVideoSaved){
                        setShowTestState(false);
                        setShowButtons(true);
                    }
                    else if(isSurveyPosted && !isVideoSaved){
                        setShowTestState(true);
                        setShowButtons(true);
                        setSavingInfoText(
                            'No cierres esta pestaña hasta que se haya subido el vídeo.'
                        );
                    }
                    else if(!isSurveyPosted && isVideoSaved){
                        setShowButtons(false);
                        setShowDisplays(false);
                        setShowTestState(true);
                        setShowForm(true);
                        setSavingInfoText(
                            'Hemos recibido tu grabación, pero recuerda que el test no está completo si no contestas a estas tres preguntas: '
                        );
                    }
                }
                else{
                    authHeader().then(token =>{
                        finishTestSession(token, testInfo.user_test.id)
                        .then(()=>{
                            setSavingInfoText(
                                '¡Test completado!'
                            );
                            setShowTestState(true);
                            setShowDisplays(false);
                            setShowButtons(false);
                            setShowForm(false);
                            setShowProgress(false);
                        })
                    });
                }
            }
            else{
                setShowButtons(true);
                setShowTestState(false);
            }

        }
    }
    const getWebcamPermission = async () => {
        const videoStream = await navigator.mediaDevices.getUserMedia(webcamConstraints);
        const camB = {
            stream: videoStream,
            mimeType: 'video/webm',
            FPS: 30,
            fileName: "cam.webm"
        }
        setWebcamBuild(camB)
    }

    const getScreenPermission = async () => {
        const screenStream = await navigator.mediaDevices.getDisplayMedia(screenConstraints);
        const screenB = { 
            stream: screenStream,
            mimeType: 'video/webm',
            FPS: 30,
            fileName: "screen.webm"
        }
        setScreenBuild(screenB)
    }

    const getPermissions = async () => {
        if ("MediaRecorder" in window) {
            try {
                await getWebcamPermission();
                await getScreenPermission();
                setPermission(true);
                setText("Cambiar la pantalla compartida");
            }
            catch(err){
                setHadErrors(true);
                alert(err);
            }
        }
        else{
            alert("The MediaRecorder API is not supported in your browser.");
        }
    }

    const startRecordingHandler = () =>{    
        if (startRecordingButton.current && startRecordingButton.current){
            startRecordingButton.current.disabled = true;
            stopRecordingButton.current.disabled = false;
            startRecordingScreen()
            setIsRecording(true)
        }
    }

    const startRecordingScreen = () => {
        const media_webcam = new MediaRecorder(screenBuild.stream);
        const media_screen = new MediaRecorder(webcamBuild.stream);

        webcamRecorder.current = media_webcam
        let localWebcamChunks = [];
        webcamRecorder.current.ondataavailable = (e) => {
            if (typeof e.data === "undefined") return;
            if (e.data.size > 0 ) {
                let chunk = e.data
                localWebcamChunks.push(chunk)
            }
        };

        setWebcamChunks(localWebcamChunks)
        screenRecorder.current = media_screen
        let localScreenChunks = [];

        screenRecorder.current.ondataavailable = (e) => {
            if (typeof e.data === "undefined") return;
            if (e.data.size > 0 ) {
                let chunk = e.data
                localScreenChunks.push(chunk)
            }
        };

        webcamRecorder.current.start(100);
        screenRecorder.current.start(100);
        setScreenChunks(localScreenChunks)
        
        setStartTime(Date.now())
    }

    const fixRecords= async(screenBlob, webcamBlob, duration) =>{
        const fixedCamBlob =   webmFixDuration(screenBlob, duration);
        const fixedScreenBlob =  webmFixDuration(webcamBlob, duration);
        const [fixedCam, fixedScreen] = await Promise.all([fixedCamBlob,fixedScreenBlob])
        return {fixedCam, fixedScreen}
    }

    const stopRecordingHandler = async () =>{
        setShowProgress(true);
        setIsRecording(false);
        setIsSaving(true);
        setShowDisplays(false);
        setShowButtons(false);
        setShowTestState(true);
        setSavingInfoText(
            "No cierres esta pestaña hasta que se haya subido el vídeo"
        );
        const duration = Date.now() - startScreenTime;
        webcamRecorder.current.stop()
        screenRecorder.current.stop()
        webcamRecorder.current.onstop = () => {
            screenRecorder.current.onstop = () => {
                const screenBlb = new Blob(screenChunks, { type: webcamBuild.mimeType });
                const webcamBlb = new Blob(webcamChunks, { type: screenBuild.mimeType });
                fixRecords(screenBlb,webcamBlb, duration)
                    .then((recordsFixed)=>{
                        try {
                            zip.file(webcamBuild.fileName, recordsFixed.fixedCam)
                            zip.file(screenBuild.fileName, recordsFixed.fixedScreen)
                            setSavingInfoText(
                                "No cierres esta pestaña hasta que se haya subido el vídeo"
                            );
                            zip.generateAsync({type:"blob"}).then(function(content){
                                if(testInfo!=null) {
                                    authHeader().then(token =>{
                                        FragmentFileServiceUpload(
                                            setProgress,
                                            setHadErrors,
                                            testInfo.user_test.id,
                                            token,
                                            content,
                                            testInfo.slug
                                        )
                                        .then(()=>{
                                            setIsVideoSaved(true);
                                            setIsVideoSaved(true);
                                            setIsSaving(false);
                                        })
                                        .catch((err)=>{
                                            console.log(err);
                                            setHadErrors(true);
                                        });
                                    });
                                }
                                else{
                                    saveAsService(content,'beetested.zip');
                                    // reseting all the useStates
                                    setWebcamChunks([])
                                    setScreenChunks([])
                                    startRecordingButton.current.disabled = false;
                                    stopRecordingButton.current.disabled = true;    
                                }
                            
                            })
                            .then(()=>{
                                setWebcamChunks([])
                                setScreenChunks([])
                                startRecordingButton.current.disabled = false;
                                stopRecordingButton.current.disabled = true;
                                updateState();
                            })
                            .catch((err)=>{
                                console.log(err);
                                setHadErrors(true);
                            });
                        }  
                        catch{
                            setHadErrors(true);
                        }  
                    })
            }
        }
        
    }


      
    return (
        <>
        {/* TODO SEPARATE INTO COMPONENTS */}
            {showProgress &&
                <div className="beetested-container loading-container">
                    <div className="progressbar-container">
                        <CircularProgressBar
                            label = {loadingLabel}
                            indicatorColor={loadingIndicatorColor}
                            trackColor= {loadingIndicatorColor}
                            labelColor={loadingLabelColor}
                            progress={progress}
                            trackWidth={5}
                            indicatorWidth={10}
                        />
                    </div>
                </div>
            }
            
            { showTestState &&   
                <div className="beetested-container loading-container">
                    <div className="looading-info">
                        <p>{savingInfoText}</p>
                        {isCompleted && 
                        <Link to="https://app.beetested.com/home">
                            <button> Beetested Demos </button>
                        </Link>}

                    </div>
                </div>
            }

            { showForm &&
                <div className="beetested-container loading-container">
                    <TestForm 
                        testInfo={testInfo}
                        setSurveyPosted={setSurveyPosted}
                        setHadErrors={setHadErrors}
                        updateState={updateState}
                    />
                </div>
            }
        
        { showButtons &&
        <div className='mainbuttons'>
            {!isCompleted && !isVideoSaved &&
                <>
                    <div className='permissions'>
                        <button className='permissionsButton' onClick={
                            ()=>{
                                if (screenBuild && webcamBuild){
                                    screenBuild.stream.getTracks().forEach(track => {
                                        track.stop();
                                    })
                                    webcamBuild.stream.getTracks().forEach(track => {
                                        track.stop();
                                    })
                                }
                                getPermissions()
                                .then(()=>{
                                    setPermission(true);
                                    setShowDisplays(true);
                                    setText("Cambiar la pantalla compartida");
                                })
                                .catch((err)=>{ 
                                    console.log(err);
                                    setHadErrors(true);
                                });
                            }
                        }> {innerText} </button>
                    </div>
                    {permissions && 
                        <div className='recorder'>
                            <button
                                ref= {startRecordingButton}
                                id="start"
                                className="btn start-recording btn-recording" 
                                onClick={startRecordingHandler}>
                                    Comenzar grabación
                            </button>

                            <button
                                id="stop"
                                ref = {stopRecordingButton}
                                className="btn stop-recording btn-recording"
                                onClick={stopRecordingHandler}>
                                {innerButton}
                            </button>
                        </div>
                    }
                </>
            }          
        </div>
        }

        { showDisplays &&
            <div className='videoCompound '>
                <div className='embed-responsive embed-responsive-16by9'>
                    <ScreenVideo screenStream={screenBuild}/> 
                    <WebcamVideo webcamStream={webcamBuild}/>
                </div>
            </div>
        }
    </>
    );
}