import React from 'react';
import { ReactComponent as Logo } from '../../beetested_logo_transparent.svg';
import {
    Link
} from 'react-router-dom';
import './GearsNav.css';

export default function GearsNav(){

    return (
        <>
            <nav className="custom-navbar">
 
                    <div className="brand">
                        <Link className="navbar-brand" to="/">
                            <Logo className="logo"/>
                        </Link>
                    </div>
            </nav>
        </>       
    )
}