import './App.css';
import GearsNav from './components/GearsNav/GearsNav.jsx'
import Recorder from './components/Recorder/Recorder';
import toast, { Toaster } from 'react-hot-toast';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { useEffect } from 'react';

  


function App() {
  useEffect(()=>{
    const  userAgent = navigator.userAgent;
    const isChrome = userAgent.match(/chrome/i);

    if (!isChrome) {
      toast(
        <div>
          <span>Esta herramienta <span  className='textColorRed'>sólo está 100% soportada</span> en Chrome.</span>
          <br/>
            <span>Te recomendamos descargar este navegador:</span><br/>
            <a className='downloadChrome'
              href='https://www.google.com/intl/es_es/chrome/?brand=CHBD&gclid=Cj0KCQiApKagBhC1ARIsAFc7Mc69ACNqPQH6OuJrx7nuu9MJykdR8MDqWkvyL7g4nXyTk926LjjAVIUaAlQnEALw_wcB&gclsrc=aw.ds'
              target='_blank'
              rel='noreferrer'
            >
              Download Chrome!  
            </a>
        </div>, {duration: 8000}
      )
    } 
  })

  return (
    <div className="App">
      <div className="App-content">
          <Routes>
            <Route path="/?:id?" element = {
              <>
              <Toaster position= 'top-right'  toastOptions={{
                widht:'45rem',
                style: {
                  fontSize: '20px',
                  background: '#FABC2A',
                  color:'#1D201F'
                }}}
              />
              <GearsNav/>
              <div className='main-container'>
                <Recorder/>
              </div> 
            </>           
          }/>
          </Routes>
      </div>
    </div> 
  );
}

export default App;
