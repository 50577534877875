const { REACT_APP_API_URL, REACT_APP_GEARS_URL } = process.env;


export const API_URL = REACT_APP_API_URL;
export const GEARS_URL = REACT_APP_GEARS_URL;
export const JWT_ACCESS_COOKIE_NAME = 'jwt_access_token';
export const JWT_REFRESH_COOKIE_NAME = 'jwt_refresh_token';

// Adjust of chunk size of files to 10 mb
export const FILE_CHUNK_SIZE = 10_000_000 //Size in bytes
