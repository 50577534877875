import './CircularProgressBar.css'

const CircularProgressBar = (props) => {

    props = {
        size: 150,
        progress: 0,
        trackWidth: 10,
        trackColor: `#ddd`,
        indicatorWidth: 10,
        indicatorColor: `#07c`,
        indicatorCap: `round`,
        label: `Loading...`,
        labelColor: `#333`,
        spinnerMode: false,
        spinnerSpeed: 1,
        ...props
    }
    const center = props.size / 2;
    const radius = center - (props.trackWidth > props.indicatorWidth ? props.trackWidth : props.indicatorWidth);
    const dashArray = 2 * Math.PI * radius;
    const dashOffset = dashArray * ((100 - props.progress) / 100);

    const hideLabel = (props.size < 100 || !props.label.length || props.spinnerMode) ? true : false;


    return (
        <>
          <div
            className="svg-pi-wrapper"
            style={{ width: props.size, height: props.size }}
          >
            <svg
              className="svg-pi" 
              style={{ width: props.size, height: props.size }}
            >
              <circle
                className="svg-pi-track"
                cx={center}
                cy={center}
                fill="transparent"
                r={radius}
                stroke={props.trackColor}
                strokeWidth={props.trackWidth}
              />
              <circle
                className={`svg-pi-indicator ${
                  props.spinnerMode ? "svg-pi-indicator--spinner" : ""
                }`}
                style={{ animationDuration: props.spinnerSpeed * 1000 }}
                cx={center}
                cy={center}
                fill="transparent"
                r={radius}
                stroke={props.indicatorColor}
                strokeWidth={props.indicatorWidth}
                strokeDasharray={dashArray}
                strokeDashoffset={dashOffset}
                strokeLinecap={props.indicatorCap}
              />
            </svg>


            {!hideLabel && (
                <div 
                    className="svg-pi-label" 
                    style={{ color: props.labelColor }}
                >
                    <span className="svg-pi-label__loading">
                    {props.label}
                    </span>

                    {!props.spinnerMode && (
                        <span className="svg-pi-label__progress">
                            {`${
                            props.progress > 100 ? 100 : props.progress
                            }%`}
                        </span>
                    )}
                </div>
                )}
          </div>
        </>
    )
}

export default CircularProgressBar;