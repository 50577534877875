import { useState } from "react";
import TestingService from '../../services/testing.service';
import authHeader from '../../services/auth-header';
import './TestForm.css'

const TestForm = ({testInfo, setSurveyPosted, setHadErrors, updateState}) => {
    const {
        submitSurvey
    } = TestingService();

    const [survey, setSurvey] = useState({
        grade: 0,
        type_xp: 0,
        genre_xp: 0,
        opinion: '',
    });

    const handleSubmit = (event) => { 
        event.preventDefault();   
        authHeader().then(token => {
            // Submit survey
            const surveyData = {
                user_product_test_rel_id: testInfo.user_test.id,
                ...survey
            }
            submitSurvey(token, surveyData)
            .then((res) => {
                setSurveyPosted(true)
            }).catch((err) => {
                setSurveyPosted(false)
                setHadErrors(true)
            });
        })
        .catch(err=>{
            setHadErrors(true)
        });
    };

    return (
        <div className='test-container'>
            <div className='beetested-container'>
            <div className='description-text'>
                    <div className='form'>
                        <div className="question">
                            <p className="needs-aclaratory">¿Qué nota le pondrías al juego?</p>
                            <p className="aclaratory">Del 0 al 10</p>
                            <div className="slider-container">
                                <span id="gradeRangeValue" className="range-value">{survey.grade}</span>
                                <input
                                    className="range"
                                    type="range"
                                    name="grade"
                                    value={survey.grade}
                                    min="0" max="10"
                                    step="1"
                                    onChange={(e) => setSurvey({...survey, grade: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="question">
                            <p className="needs-aclaratory">¿Cuánta experiencia tienes en juegos de acción?</p>
                            <p className="aclaratory">(0: No suelo jugar mucho. 5: Es mi género favorito)</p>
                            <div className="slider-container">
                                <span id="genreXpValue" className="range-value">{survey.genre_xp}</span>
                                <input
                                    className="range"
                                    type="range"
                                    name="genre_xp"
                                    value={survey.genre_xp}
                                    min="0" max="5"
                                    step="1"
                                    onChange={(e) => setSurvey({...survey, genre_xp: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="question">
                            <p className="needs-aclaratory">¿Cuánta experiencia tienes en juegos de un jugador?</p>
                            <p className="aclaratory">(0: No suelo jugar mucho. 5: Es mi tipo de juego favorito)</p>
                            <div className="slider-container">
                                <span id="typeXpValue" className="range-value">{survey.type_xp}</span>
                                <input
                                    className="range"
                                    type="range"
                                    name="type_xp"
                                    value={survey.type_xp}
                                    min="0" max="5"
                                    step="1"
                                    onChange={(e) => setSurvey({...survey, type_xp: e.target.value})}
                                />
                            </div>
                        </div>        
                        <div className="question">
                            <p>¿Quieres añadir algo más?</p>
                            <textarea
                                name="opinion" cols="40" rows="5" placeholder="Aquí puedes explayarte :)"
                                value={survey.opinion}
                                onChange={e => setSurvey({...survey, opinion: e.target.value})}
                            />
                        </div>
                        <div className='submitButt-container'>
                            <button onClick={handleSubmit}>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TestForm;