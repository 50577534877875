import {FILE_CHUNK_SIZE} from '../constants';
import SparkMD5 from 'spark-md5'
import axios from 'axios';
import { API_URL } from '../constants';

export default async function FragmentFileServiceUpload(setProgress, setHadErrors, userTestRelId, token, file, fileName) {
    const ApiUrl =  API_URL + '/tests/upload'

    let firstResponse = null;
    let blobSlice = 
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice;

    let chunks = Math.ceil(file.size / FILE_CHUNK_SIZE);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer();
    let fileReader = new FileReader();
    let chunkToUpload = null;
    let start = currentChunk * FILE_CHUNK_SIZE;
    let end = Math.min(start + FILE_CHUNK_SIZE, file.size);
    let contentRange = `bytes ${start}-${ end -1}/${file.size}`;


    fileReader.onload = function (e) {

        contentRange = `bytes ${start}-${ end -1}/${file.size}`;
        chunkToUpload = new Blob([e.target.result], { type: file.type });

        if (currentChunk > 0){
            let formData = new FormData();
            formData.append('filename', fileName);
            formData.append('file', chunkToUpload);
            formData.append('user_product_test_rel',userTestRelId);
            axios.put(
                firstResponse.data.url, formData,
                {
                    headers: {
                        ...token,
                        'Content-Range': contentRange,
                        'Content-Type': file.type
                    }
                }
            )
            .then(res => {
                setProgress(parseFloat(currentChunk*100/chunks).toFixed(0));
                loadNext();
                setHadErrors(false);
            })
            .catch(err =>{
                console.log(err);
                setHadErrors(true);
                return;
            });
        }
        else {
            let formData = new FormData()
            formData.append('filename', fileName);
            formData.append('file', chunkToUpload);
            formData.append('user_product_test_rel',userTestRelId);
            axios.put(
                ApiUrl, formData,
                { 
                    headers: {
                        ...token,
                        'Content-Range': contentRange,
                        'Content-Type': file.type
                    }
                },
            )
            .then(res => {
                firstResponse = res;
                setProgress(parseFloat(currentChunk*100/chunks).toFixed(0));
                loadNext();
            })
            .catch(err =>{ 
                console.log(err)
                setHadErrors(true)
                return;
            });
        }
        currentChunk++;
        spark.append(e.target.result);                      
    };

    fileReader.onerror = function () {
        console.warn('oops, something went wrong.');
    };

    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));

    async function loadNext(){
        start = currentChunk * FILE_CHUNK_SIZE;
        end = Math.min(start + FILE_CHUNK_SIZE , file.size);
        if (currentChunk<chunks && fileReader.readyState !== 1) {
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        else if (currentChunk>=chunks) {
            const md5 = spark.end();
            return await axios.post(
                firstResponse.data.url,
                {"md5": md5},
                { 
                    headers: token
                }
            );
        }
    }
}
