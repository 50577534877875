export default function saveAsService(blob, filename) {

    if (typeof navigator.msSaveOrOpenBlob !== 'undefined') {
      return navigator.msSaveOrOpenBlob(blob, filename);
    } 
    else if (typeof navigator.msSaveBlob !== 'undefined') {
      return navigator.msSaveBlob(blob, filename);
    } 
    else {
      const url = window.URL.createObjectURL(new Blob([blob]), filename)
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename,
      );

      (document.body || document.documentElement).appendChild(link);
      if (typeof link.click === 'function') {
        link.click();
      }
      else {
        link.target = '_blank';
        link.dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        }));
      }
      URL.revokeObjectURL(link.href);
    }
  }
  